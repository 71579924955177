<template>
  <v-container fluid>
    <v-card max-width="30em" class="mx-auto">
      <v-card-text class="d-flex justify-center">
        <div class="card-body " ref="capture">
          <div class="frames"
               :style="{'background-image': 'url(\''+ listFrame[frameSelect].url +'\')' }"
          ></div>
          <img :src="avatar" class="card-img avatar"/>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="d-flex justify-center">
        <v-btn rounded small @click="upload=true">
          <v-icon small>mdi-camera</v-icon>
          Chọn ảnh khác
        </v-btn>
        <v-btn rounded small @click="getAvatarZalo"
               v-if="oauthCode===null || oauthCode ==='not_allow'"
               color="primary">Sử dụng ảnh zalo</v-btn>
      </v-card-actions>
    </v-card>

    <v-list>
      <v-list-item-group v-model="frameSelect" active-class="blue--text">
        <v-row class="text-center mt-5 d-flex justify-center">
          <template v-for="(item, index) in listFrame">
            <v-col cols="3" sm="2" md="1" :key="index">
              <v-list-item :key="item.title" class="px-0">
                <v-img :src="item.url" class="rounded"></v-img>
              </v-list-item>
            </v-col>
          </template>
        </v-row>
      </v-list-item-group>
    </v-list>

    <v-row class="text-center mt-8">
      <v-col cols="12">
        <v-btn rounded small @click="saveImage" color="success" :loading="generating">
          <v-icon small>mdi-download</v-icon>
          Tạo ảnh
        </v-btn>
      </v-col>
    </v-row>
    <avatar-cropper
        @cancel="upload=false"
        @submit="handleSubmited" :trigger="upload"/>
    <v-dialog v-model="showDialogSaveImage" max-width="500px">
      <v-card>
        <v-card-text>
          <div class="d-flex justify-center">
            <img alt="avatar-zalo-canthotourism.vn.png" class="my-4" height="240" width="240" :src="dataUrl"/>
          </div>
          <v-alert type="info" dismissible>
            Nếu trình duyệt không tự động tải ảnh. <br>
            Vui lòng <strong>nhấn giữa</strong> vào ảnh và chọn lưu về máy.
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="showDialogSaveImage = false">
            Đóng
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import AvatarCropper from "./avatar-cropper";
import domtoimage from 'dom-to-image';
import download from 'downloadjs';
import axios from 'axios';

export default {
  components: {AvatarCropper},
  data() {
    return {
      listFrame: [
        {
          'id': 1,
          'title': 'f1',
          'url': '/assets/images/frames/f1.png',
        },
        {
          'id': 2,
          'title': 'f2',
          'url': '/assets/images/frames/f2.png',
        }
      ],
      upload: false,
      generating: false,
      frameSelect: 0,
      dataUrl: null,
      showDialogSaveImage: false,
      avatar: "/assets/images/berserk.png",
      oauthCode: this.$cookies.get("oauthCode")
    };
  },
  computed: {},
  mounted() {
    let oauthCode = this.oauthCode;
    if (oauthCode === null) {
      this.getAvatarZalo();
    } else {
      if (oauthCode !== 'not_allow') {
        axios.get('/zalo-sdk/get-avatar-zalo.php', {
          params: {
            code: oauthCode
          }
        }).then((docs) => {
          if (docs.data != '') {
            this.avatar = docs.data;
          }
        })
      }
    }
  },
  methods: {
    handleSubmited(imageUrl) {
      this.upload = false;
      this.avatar = imageUrl
    },
    getAvatarZalo() {
      window.location.href = 'https://oauth.zaloapp.com/v3/auth?app_id=3728313236072083512&redirect_uri=https://avatar.tourismcantho.vn/zalo-sdk';
    },
    saveImage() {
      this.generating = true;
      domtoimage.toPng(this.$refs.capture)
          .then((dataUrl) => {
            axios.post('https://api.cloudinary.com/v1_1/dw39oopiw/image/upload', {
              file: dataUrl,
              public_id: "avatar-zalo-canthotourism.vn_" + (new Date()).getTime(),
              upload_preset: "avatar"
            }).then((result) => {
              console.log(result);
              this.dataUrl = result.data.url;
              this.showDialogSaveImage = true;
              download(dataUrl, 'avatar-zalo-canthotourism.vn.png', "image/png");
              this.generating = false;
            }).catch(() => {
              this.generating = false;
              this.showDialogSaveImage = true;
              download(dataUrl, 'avatar-zalo-canthotourism.vn.png', "image/png");
              alert('Lổi không thể tạo ảnh trên trình duyệt zalo! Vui long thử lại bằng trình duyệt khác')
            });
          });
    }
  }
};
</script>

<style scoped>
.avatar {
  height: 260px;
  border-radius: 6px;
  /*margin: 0;*/
  padding: 20px;
  margin: 20px;
}

.card-body {
  width: 300px;
  height: 300px;
  margin: 0;
  position: relative;
  line-height: 0;
}

.card-body .frames {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
  position: absolute;
  top: 0;
  left: 0;
}
</style>
